<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แก้ไขสาขาวิชา</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <v-form
          ref="form"
          lazy-validation
        >
            <v-container class="body-2">
                <v-row>
                    <v-col cols="3">
                        <v-subheader>ชื่อสาขาวิชา</v-subheader>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            v-model="schoolName"
                            outlined
                            dense
                            :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mx-4 mb-5" />
                <v-row>
                    <v-col>
                        <v-btn depressed color="success" @click="save()">
                            บันทึก
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['schoolId'],
    data: () => ({
        schoolName: '',
        formChkErrors: false,
    }),
    async mounted() {
        this.$store.commit('setLoading', true)
        await this.getData()
        this.$store.commit('setLoading', false)
    },
    methods: {
			async getData() {
				try {
					const res = (await Api().get('school/' + this.schoolId)).data;
					//const dd = JSON.parse(res);

					if (res.status == 'success') {
						this.schoolName = res.data.school_name;

					} else {
						console.log(res.data);
					}

				} catch (err) {
					console.log(err.message);
				}
			},
			async save() {
					this.$store.commit('setLoading', true)
					const req = {
							school_name: this.schoolName
					}

					this.formChkErrors = false
					this.formChkErrors = this.$refs.form.validate()

					if(this.formChkErrors){
            let res = (await Api().put("school/" + this.schoolId, req)).data;

            if (res.status == 'success') {
              this.$store.commit('setLoading', false)
              await this.$refs.confirm.open('ข้อความ', 'บันทึกข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
              this.$router.push('/school')
            }else{
              this.$store.commit('setLoading', false)
            }
					}else{
							this.$store.commit('setLoading', false)
					}
			
			}
    }
};
</script>